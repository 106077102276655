import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import headerStyle from '../../utilities/Sass/headerStyle.module.css'
import homeIcon from '../../utilities/img/icon/home.png'
import about from '../../utilities/img/icon/contatti.png'
import services from '../../utilities/img/icon/camion.png'
import norms from '../../utilities/img/icon/libro.png'
import point from '../../utilities/img/icon/PUNTO.png'
import news from '../../utilities/img/icon/news.png'
import contatti from '../../utilities/img/icon/cuffie.png'
import hand from '../../utilities/img/icon/mano.png'
import Logo from '../../utilities/img/LOGO.png'

import normativedoc from '../../utilities/docs/normativa.pdf'

function Header() {
    const [open, openMenu] = useState(false)

    return (
        <div className={headerStyle.cont__header}>
            <ul className={headerStyle.cont__header_list}>
                <li>
                    <Link to="/">
                        <img src={homeIcon} alt="Header Icon" />
                        <p>Home</p>
                    </Link>
                </li>

                <li>
                    <Link to="/chiSiamo">
                        <img src={about} alt="Header Icon" />
                        <p>Chi Siamo</p>
                    </Link>
                </li>

                <li>
                    <Link to="/servizi">
                        <img src={services} alt="Header Icon" />
                        <p>Servizi</p>
                    </Link>
                </li>

                <li>
                    <a href={normativedoc}>
                        <img src={norms} alt="Header Icon" />
                        <p>Normative</p>
                    </a>
                </li>
            </ul>

            <div className={headerStyle.cont__header_logo}>
                <Link to="/">
                    <img src={Logo} alt="Header Icon" />
                </Link>
            </div>

            <ul className={headerStyle.cont__header_list}>
                <li>
                    <Link to="/faq">
                        <img src={point} alt="Header Icon" />
                        <p>F.A.Q</p>
                    </Link>
                </li>

                <li>
                    <Link to="/notizie">
                        <img src={news} alt="Header Icon" />
                        <p>News</p>
                    </Link>
                </li>

                <li>
                    <Link to="/contatti">
                        <img src={contatti} alt="Header Icon" />
                        <p>Contatti</p>
                    </Link>
                </li>

                <li>
                    <Link to="/lavora-con-noi">
                        <img src={hand} alt="Header Icon" />
                        <p id="work">Lavora con Noi</p>
                    </Link>
                </li>
            </ul>

            <div className={headerStyle.cont__header_mobile} onClick={open ? () => openMenu(false) : () => openMenu(true)} >
                <span style={{'backgroundColor': open ? '#fff' : '#8bc61c'}}></span>
                <span style={{'backgroundColor': open ? '#fff' : '#8bc61c'}}></span>
                <span style={{'backgroundColor': open ? '#fff' : '#8bc61c'}}></span>
            </div>

            <ul className={headerStyle.cont__header_mobile_list} style={{'transform': open ? 'translateY(30vh)' : 'translateY(-101%)'}}>
                <li onClick={() => openMenu(false)}>
                    <Link to="/">
                        <img src={homeIcon} alt="Header Icon" />
                        <p>Home</p>
                    </Link>
                </li>

                <li>
                    <Link onClick={() => openMenu(false)} to="/chiSiamo">
                        <img src={about} alt="Header Icon" />
                        <p>Chi Siamo</p>
                    </Link>
                </li>

                <li>
                    <Link onClick={() => openMenu(false)} to="/servizi">
                        <img src={services} alt="Header Icon" />
                        <p>Servizi</p>
                    </Link>
                </li>

                <li>
                    <a href={normativedoc}>
                        <img src={norms} alt="Header Icon" />
                        <p>Normative</p>
                    </a>
                </li>

                <li>
                    <Link onClick={() => openMenu(false)} to="/faq">
                        <img src={point} alt="Header Icon" />
                        <p>F.A.Q</p>
                    </Link>
                </li>

                <li>
                    <Link onClick={() => openMenu(false)} to="/notizie">
                        <img src={news} alt="Header Icon" />
                        <p>News</p>
                    </Link>
                </li>

                <li>
                    <Link onClick={() => openMenu(false)} to="/contatti">
                        <img src={contatti} alt="Header Icon" />
                        <p>Contatti</p>
                    </Link>
                </li>

                <li>
                    <Link onClick={() => openMenu(false)} to="/lavora-con-noi">
                        <img src={hand} alt="Header Icon" />
                        <p id="work">Lavora con Noi</p>
                    </Link>
                </li>
            </ul>

        </div>
    )
}

export default Header
