import React from "react";
import Intro from "../Intro/Intro";

import aboutStyle from "../../../utilities/Sass/chiSiamo.module.css";

import alboNazionale from "../../../utilities/img/logo1-gestori.png";
import snr from "../../../utilities/img/snr-logo-.png";
import certificazioni from "../../../utilities/img/icon/certificazioni.png";
import cc_caserta from "../../../utilities/img/logo_cc_caserta.png";

import cert1 from "../../../utilities/img/cert/iso_9001.PNG";
import cert2 from "../../../utilities/img/cert/iso_14001.PNG";
import cert3 from "../../../utilities/img/cert/iso_18001.PNG";
import cert4 from "../../../utilities/img/cert/iso_37001.PNG";
import cert5 from "../../../utilities/img/bentley_soa.jpeg";

function ChiSiamo() {
  return (
    <div className={aboutStyle.cont__about}>
      <Intro />

      <div className={aboutStyle.cont__about_description}>
        <h2>
          La <b style={{ color: "#8bc61c" }}>Società</b>
        </h2>
        <p>
          Fondata nel 2015 a Santa Maria Capua Vetere, Ever Power s.r.l. è
          specializzata nella gestione ambientale e offre una vasta gamma di
          servizi certificati. L'azienda è leader nel trattamento e nella
          rimozione dell'amianto, garantendo sicurezza e conformità alle
          normative attraverso l'analisi preliminare, la confinazione delle aree
          di bonifica, il trattamento con agenti incapsulanti e lo smaltimento
          in centri autorizzati.
        </p>
        <p>
          Ever Power fornisce servizi completi di bonifica, smaltimento e
          trasporto dell'amianto, seguendo protocolli rigorosi che includono
          l'analisi preliminare, la confinazione dell'area di bonifica, il
          trattamento con agenti incapsulanti, l'utilizzo di dispositivi di
          sicurezza, il trasporto autorizzato e lo smaltimento in centri
          specializzati. Inoltre, offre un servizio sicuro per la rimozione e il
          trasporto delle guaine bituminose, garantendo il rispetto delle
          normative vigenti e la protezione dell'ambiente.
        </p>
        <p>
          L'azienda si occupa di ristrutturazioni e ripristino di impianti
          elettrici e idraulici, fornendo interventi di alta qualità per
          assicurare il miglior risultato possibile. Gestisce la raccolta, il
          trasporto e lo smaltimento di rifiuti speciali non pericolosi e
          pericolosi, collaborando con impianti autorizzati per garantire la
          conformità e la sicurezza. Ever Power offre servizi di analisi di
          caratterizzazione dei rifiuti, determinando le caratteristiche
          specifiche per garantire la gestione appropriata e la tutela del
          produttore.
        </p>
        <p>
          La società fornisce consulenze energetiche dettagliate per aiutare i
          clienti a comprendere e ottimizzare i loro consumi energetici,
          migliorando le performance dei loro sistemi. Supporta le imprese
          nell'adempimento delle normative ambientali, assicurando operazioni
          conformi e sicure. Inoltre, installa pannelli fotovoltaici,
          promuovendo la produzione di energia pulita e sostenibile, con
          benefici per l'ambiente e la salute pubblica.
        </p>
        <p>
          Ever Power offre una gestione completa dei rifiuti per le aziende,
          promuovendo pratiche etiche e sostenibili dal punto di vista
          ambientale. Si occupa del ripristino di tettoie domestiche e capannoni
          industriali, utilizzando materiali certificati e sfruttando sgravi
          fiscali per offrire soluzioni economicamente vantaggiose.
        </p>
        <p>
          La società fornisce interventi di pulizia, disinfezione e
          sanificazione per uffici, capannoni, aree esterne, cinema e teatri,
          sia pubblici che privati, garantendo ambienti salubri e igienici. Ever
          Power offre anche servizi di deblattizzazione e derattizzazione,
          utilizzando metodi efficaci e sicuri per eliminare infestazioni e
          proteggere gli ambienti da parassiti indesiderati.
        </p>
        <p>
          Con un team di tecnici qualificati e costantemente aggiornati, Ever
          Power s.r.l. è in grado di offrire soluzioni complete e
          personalizzate, affrontando con competenza e professionalità le sfide
          ambientali dei propri clienti su tutto il territorio nazionale. Grazie
          all'ampia gamma di servizi offerti, l'azienda è il partner ideale per
          enti pubblici, società e privati che cercano un approccio integrato e
          sostenibile nella gestione ambientale.
        </p>
      </div>

      <ul className={aboutStyle.cont__about_enti}>
        <li>
          <img src={alboNazionale} alt="Albo Nazionale Gestori Ambientali" />

          <p>
            Ever Power è iscritta all’Albo Nazionale Gestori Ambientali nelle
            categorie: - Raccolta e trasporto di rifiuti speciali pericolosi -
            Attività di bonifica di beni contenenti amianto effettuata su
            matrici cementizie o resinoidi
          </p>
        </li>

        <li>
          <img src={snr} alt="SNR" />

          <p>
            Ever Power ha implementato: - un sistema di Gestione per l’Ambiente
            conforme alla norma UNI EN ISO 14001:2004 - un sistema di Gestione
            per la Salute e Sicurezza conforme alla norma BS OHSAS 18001:2007
          </p>
        </li>

        <li>
          <img src={cc_caserta} alt="Camera di Commercio Caserta" />

          <p>
            La Camera di Commercio di Caserta realizza iniziative volte a
            favorire la crescita del tessuto economico produttivo, esaltando le
            potenzialità locali, attraverso la valorizzazione delle risorse.
          </p>
        </li>
      </ul>

      <img
        src={certificazioni}
        className={aboutStyle.cont__about_extraImage}
        alt="Certificazioni"
      />

      <h2>
        <b style={{ color: "#8bc61c" }}>
          SIAMO CERTIFICATI ED ESPERTI PER OGNI LAVORO..
        </b>
      </h2>

      <ul className={aboutStyle.cont__about_certificazioni}>
        <li>
          <img src={cert1} alt="SNR" />
        </li>

        <li>
          <img src={cert2} alt="SNR" />
        </li>

        <li>
          <img src={cert3} alt="SNR" />
        </li>

        <li>
          <img src={cert4} alt="SNR" />
        </li>

        <li>
          <img src={cert5} alt="SOA" />
        </li>
      </ul>

      <ul className={aboutStyle.cont__about_certificationList}>
        <li>Categoria 1: Raccolta e trasporto di rifiuti urbani.</li>

        <li>
          Categoria 2BIS: Produttori iniziali di rifiuti non pericolosi che
          effettuano operazioni di raccolta e trasporto dei propri rifiuti,
          nonché i produttori iniziali idi rifiuti pericolosi che effettuano
          operazioni di raccolta e trasporto dei propri rifiuti pericolosi.
        </li>

        <li>
          Categoria 4: Raccolta e trasporto di rifiuti speciali non pericolosi
          in conto proprio e in conto terzi.
        </li>

        <li>
          Categoria 5: Raccolta e trasporto di rifiuti speciali pericolosi in
          conto proprio e in conto terzi.
        </li>

        <li>Categoria 9: Bonifica dei siti inquinati.</li>

        <li>Categoria OG1: opere edili per edifici civili ed industriali.</li>

        <li>
          Categoria: OG12: Opere ed impianti di bonifica e protezione
          ambientale.
        </li>

        <li>
          Certificazione di Sistemi di Gestione per la prevenzione della
          corruzione UNI ISO 37001:2016;
        </li>

        <li>
          Certificazione Ambientale ai sensi della Norma UNI EN ISO 14001;
        </li>

        <li>
          Certificazione di Sistemi di Gestione per la qualità UNI EN ISO
          9001:2015;
        </li>

        <li>
          Certificazione di Sistemi di Gestione per la Salute e Sicurezza sul
          lavoro(OHSAS), regolata dalla Norma BS OHSAS 18001:2007;
        </li>

        <li>
          Camera di Commercio Della Provincia di Caserta: Numero REA(Repertorio
          Attività economiche)CE - 2855337;
        </li>

        <li>Cassa Edile della Provincia di Caserta N°2855337;</li>

        <li>
          Albo Nazionale Gestori Ambientali - N° NA1504:
          <ul>
            <li>
              - Categoria 2bis: produttori iniziali di rifiuti non pericolosi
              che effettuano operazioni di raccolta e trasporto dei propri
              rifiuti, nonché i produttori iniziali di rifiuti pericolosi che
              effettuano operazioni di racfcolta e trasporto dei propri rifiuti
              pericolosi;
            </li>

            <li>
              - Categoria 5: raccolta e trasporto di rifiuti speciali
              pericolosi;
            </li>

            <li>
              - Categoria 8: intermediazione e commercio di rifiuti senza
              detenzione dei rifiuti stessi;
            </li>

            <li>
              - Categoria 10 A: attività di bonifica di beni contenenti amianto
              effettuata sui seguenti materiali: materiali edici contenenti
              amianto legato in matrici cementizie o resinoidi;
            </li>

            <li>
              - Categoria 10 B: attività di bonifica effettuata su materiali
              d'attrito, materiali isolanti (pannelli, coppelle, carte e
              cartoni, tessili, materiali spruzzati, smalti, bitumi, colle,
              guarnizioni, altri materiali isolanti), contenitori a pressione,
              apparecchiature fuori uso, altri materiali incoerenti contenenti
              amianto
            </li>
          </ul>
        </li>

        <li>Certificazione ai sensi della Norma UNI CEI 11352;</li>

        <li>
          REGOLARMENTE ISCRITTA E CERTIFICATA NELLA WHITE LIST - CERTIFICATO
          ANTIMAFIA - DELLA PREFETTURA DI CASERTA (CE)
        </li>
      </ul>
    </div>
  );
}

export default ChiSiamo;
