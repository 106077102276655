import React from "react";

import { Link } from "react-router-dom";
import contactStyle from "../../utilities/Sass/contactStyle.module.css";

import contact from "../../utilities/img/icon/cuffie.png";

function ContactModule(props) {
  return (
    <form className={contactStyle.cont__form} action="">
      <img src={contact} alt="Contatti - EverPower" />

      {props.workForUs ? (
        <h2>
          Compila il <b style={{ color: "#8bc61c" }}>Modulo</b> per inviarci la{" "}
          <b style={{ color: "#8bc61c" }}>tua candidatura</b>
        </h2>
      ) : (
        <>
          <h2>
            CONTATTACI AL NUMERO: <a href="tel: +390823793131">0823 793131</a>
          </h2>
          <h2>
            Oppure Compila il <b style={{ color: "#8bc61c" }}>Modulo</b> per un
            preventivo o un{" "}
            <b style={{ color: "#8bc61c" }}>sopralluogo gratuito</b>
          </h2>
        </>
      )}

      <input type="text" placeholder="Nome - Cognome" />
      <input type="text" placeholder="Telefono - Cellulare" />
      <input type="text" placeholder="Email" />
      <input type="text" placeholder="Comune & Indirizzo" />
      {props.workForUs && <input type="file" />}
      <textarea rows="10" placeholder="Messaggio"></textarea>

      <label>
        <input type="checkbox" name="terms" required />
        Autorizzo il trattamento dei miei dati personali (ai sensi dell’art. 7
        del GDPR 2016/679 e della normativa nazionale vigente).
      </label>

      <Link style={{ color: "#666" }} to="/privacy">
        Informativa sulla <b style={{ color: "#8bc61c" }}>Privacy</b>
      </Link>

      <input
        type="submit"
        value={
          props.workForUs
            ? "Invia la tua candidatura"
            : "Richiedi un Preventivo"
        }
      />
    </form>
  );
}

export default ContactModule;
